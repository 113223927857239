import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChangeLogEntry, Patient, PatientSex, PatientTitle, Stay } from '@mona/models';
import { PersonalDataForm } from '../../models';

/**
 * Personal data form
 */
@Component({
    selector: 'app-personal-data-form',
    templateUrl: './personal-data-form.component.html',
    styleUrls: ['./personal-data-form.component.scss'],
})
export class PersonalDataFormComponent {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<PersonalDataForm>;

    /**
     * Is existing patient set
     */
    @Input() isPatientSet: boolean;

    /**
     * Patient staged changes
     */
    @Input() stagedChanges: ChangeLogEntry<Patient>[];

    /**
     * Patient staged changes
     */
    @Input() staysList: Stay[] = [];

    /**
     * Is case changed
     */
    @Input() isCaseChanged: boolean;

    /**
     * Is case changed
     */
    @Input() isPatientFromSearch: boolean;

    /**
     * BMI
     */
    @Input() bmi: string;

    /**
     * Is card appearance (click to edit)
     */
    @Input() isCardAppearance: boolean;

    /**
     * Patient number (search/clear) button tapped
     */
    @Output() patientNumberButtonTapped: EventEmitter<void> = new EventEmitter();

    /**
     * fields that changed
     */
    @Input() fieldsChangeState;

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();

    /**
     * Keyboard enter clicked
     */
    @Output() openPastStayClicked: EventEmitter<any> = new EventEmitter();
    /**
     * Keyboard enter clicked
     */
    @Output() formClicked: EventEmitter<any> = new EventEmitter();

    /**
     * Possible patient titles
     */
    patientTitle: typeof PatientTitle = PatientTitle;

    /**
     * Possible patient gender
     */
    patientSex: typeof PatientSex = PatientSex;

    /**
     * Current data
     */
    currentDate = new Date();

    /**
     * INFO: add comment
     *
     * @param event
     * @param stay
     */
    handleStayClick(event, stay) {
        event.preventDefault();
        event.stopPropagation();
        this.openPastStayClicked.emit(stay);
    }

    /**
     * INFO: add comment
     *
     * @param event
     */
    handleFormClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.formClicked.emit();
    }
}
