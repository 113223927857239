/* eslint-disable @typescript-eslint/ban-types, curly */
import { DataSource } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { isObservable, Observable, Subscription } from 'rxjs';
import { get, isEmpty, isObject } from '@mona/shared/utils';
import { UiTableRow } from './row.intf';

/** FilterPredicateFn */
export type FilterPredicateFn<T> = (row: T, filter: string) => boolean;
/** SortingDataAccessorFn */
export type SortingDataAccessorFn<T> = (row: T, filter: string) => string | number;

/**
 * Filter State Enum
 */
export enum FilterState {
    EMPTY = 'EMPTY',
    BY_COLLAPSED = 'BY_COLLAPSED',
    BY_CODE = 'BY_CODE',
    BY_STRING = 'BY_STRING',
}

/**
 * TableDataSource which is extended MatTableDataSource
 */
export class UiTableDataSource<T extends UiTableRow> extends MatTableDataSource<T> implements DataSource<T> {
    /** Subscriptions holder */
    private sourceSubscription$: Subscription;
    /** Empty attr */
    get empty() {
        return !this.data?.length;
    }
    /** Length attr */
    get length() {
        return this.data?.length || 0;
    }
    /** Stream emitting render data to the table (depends on ordered data changes). */
    get renderData(): Observable<T[]> {
        return this['_renderData'];
    }
    /** Collapsed rows by parent string  */
    private _collapsedRows = new Set<string>();
    /** Collapsed rows by parent string  */
    get collapsedRows() {
        return this._collapsedRows;
    }
    /** Filter string  */
    filterString: string = undefined;

    private _filteredCodes = new Set<string>();
    /** Holds the currently selected codes from the filter control */
    get filteredCodes(): string[] {
        return [...this._filteredCodes];
    }

    private _filteredCodesDataAccessor = 'code';
    /** Filter by code data path, e.g. `code` or `item.next.code` */
    get filteredCodesDataAccessor(): string {
        return this._filteredCodesDataAccessor;
    }
    set filteredCodesDataAccessor(value: string) {
        this._filteredCodesDataAccessor = value;
    }
    /** Original filter predicate fn */
    private originalFilterPredicate: FilterPredicateFn<T>;

    /**
     * ☝️ This trackBy function should be prefered so that it properly works with CDK table `_dataDiffer` which calculates `RenderRow` changes. Default `trackBy` function is not enough because it doesn't take into account row `values` Object changes.
     *
     * @param index
     * @param row
     * @see IterableDiffer in CdkTable
     */
    trackBy = (index: number, row: T) => {
        let result = '';

        if (row.isGroup) {
            return row.code;
        }

        const trackByValueAggregator = (r, prop = 'values'): void => {
            result += '|' + row.code;
            for (const key in r[prop]) {
                result += '|' + key || '';
                result += '|' + r[prop]?.[key]?.isStageRemoved || '';
                result += '|' + r[prop]?.[key]?.hasChanges || '';
                result += '|' + r[prop]?.[key]?.value || '';
            }

            // NOTE: if same values for 6h and 1d we need then to add date keys to trackBy
            // otherwise data will still be shown for prev interval
            if (isObject([prop])) {
                result += '|' + Object.keys(r[prop]);
            }
        };

        if (!isEmpty(row.children)) {
            row.children.forEach(child => {
                trackByValueAggregator(child);
            });
        } else if (!isEmpty(row['ventilationEntries'])) {
            // FIXME: specifically for ventilation table, remove plz
            trackByValueAggregator(row, 'ventilationEntries');
        } else {
            trackByValueAggregator(row);
        }

        return result;
    };

    /**
     * Constructor
     *
     * @param initialData some array
     * @param filterPredicate function to filter
     * @param sortingDataAccessor function to sort
     */
    constructor(
        initialData?: T[] | Observable<T[]>,
        filterPredicate?: FilterPredicateFn<T>,
        sortingDataAccessor?: SortingDataAccessorFn<T>,
    ) {
        super(isObservable(initialData) ? undefined : initialData);
        this.originalFilterPredicate = this.filterPredicate; // store MatDataSource filterPredicate fn
        this.filterPredicate = filterPredicate || this.customFilterPredicate;

        if (sortingDataAccessor) {
            this.sortingDataAccessor = sortingDataAccessor;
        }
        if (isObservable(initialData)) {
            this.subscribeToData(initialData);
        }

        if (!filterPredicate) {
            this.resetFilter();
        }
    }

    /**
     * Used by the MatTable. Called when it is destroyed. No-op.
     */
    override disconnect(): void {
        this.sourceSubscription$?.unsubscribe();
        super.disconnect();
    }

    /**
     * apply filter by row
     *
     * @param filterValue
     * @see https://v10.material.angular.io/components/table/overview#filtering
     * To override the default filtering behavior, a custom filterPredicate function can
     * be set which takes a data object and filter string and returns true if the data object is considered a match.
     */
    applyFilter(filterValue?: FilterState): void {
        this.filter = filterValue;
    }

    /**
     * Filter data by group
     *
     * @param code string
     */
    filterByGroup(code: string): void {
        if (this._collapsedRows.has(code)) {
            this._collapsedRows.delete(code);
        } else {
            this._collapsedRows.add(code);
        }
        this.applyFilter(FilterState.BY_COLLAPSED);
    }

    /**
     * Filter data by code attr
     *
     * @param codes string array
     */
    filterByCode(codes: string[]): void {
        this._filteredCodes = new Set(codes);
        this.applyFilter(FilterState.BY_CODE);
    }

    /**
     * Filter data by string match
     *
     * @param str
     */
    filterByString(str: string): void {
        this.filterString = str;
        this.applyFilter(FilterState.BY_STRING);
    }

    /**
     * Filter reset to initial state
     *
     */
    resetFilter(): void {
        this.filterString = undefined;
        this._collapsedRows = new Set();
        this._filteredCodes = new Set(this.data?.map(row => get(row, this.filteredCodesDataAccessor as Path<{}>)));
        this.applyFilter(FilterState.EMPTY);
        this._updateChangeSubscription();
    }

    /**
     * Custom filter predicate fn for this datasource to be filtered by multiple dimensions -
     * by collapsed rows, by code, by string
     *
     * @param row
     * @param filter
     */
    private customFilterPredicate: FilterPredicateFn<T> = (row: T, filter: string) => {
        if (row.skipFilter) return true;
        if (row.dataType === 'group' && !row.children) return true;
        const code = get(row, this.filteredCodesDataAccessor as Path<{}>);

        switch (filter) {
            case FilterState.BY_COLLAPSED: {
                if (!this._collapsedRows.has(row.parent)) {
                    return this._filteredCodes.has(code);
                }
                return false;
            }
            case FilterState.BY_CODE: {
                if (this._filteredCodes.has(code)) {
                    return this._collapsedRows.size
                        ? !this._collapsedRows.has(row.parent) //
                        : true;
                }
                return false;
            }
            case FilterState.BY_STRING: {
                return this.originalFilterPredicate(row, this.filterString);
            }
            case FilterState.EMPTY:
            default:
                return true;
        }
    };

    /**
     * Subscribe to initial data-as-observable
     *
     * @param initialData
     */
    private subscribeToData(initialData: Observable<T[]>) {
        this.sourceSubscription$ = initialData.pipe().subscribe((data: T[]) => {
            const prevLength = this.data?.length;
            this.data = data;
            // INFO: reset filter if new row was added to make it visible
            if (data?.length > prevLength) {
                this.resetFilter();
            }
        });
    }
}
