import { Portal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { ExternalResource } from '@mona/models';
import { DrawerNavItem } from './drawer-nav-item.model';

/**
 * DrawerSizeEnum
 */
export enum DrawerSizeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

/* eslint-disable jsdoc/require-jsdoc */
export type DrawerPortals = {
    fab?: Portal<any>;
    customHeader?: Portal<any>;
    toolbarTitle?: Portal<any>;
    toolbarSubTitle?: Portal<any>;
    toolbarActions?: Portal<any>;
};
export type DrawerPortalName = keyof DrawerPortals;
/* eslint-enable jsdoc/require-jsdoc */

/**
 * App drawer layout config
 */
export interface LayoutConfig {
    /**
     * Show left side navigation panel, aka `sidenav`
     */
    hasSidenav?: boolean;
    /**
     * Can collapse left side navigation panel, aka `sidenav`
     */
    collapsible?: boolean;
    /**
     * Show toolbar in left side navigation panel - title etc.
     */
    hasSidenavBar?: boolean;
    /**
     * Show application bar
     */
    hasAppbar?: boolean;
    /**
     * Show navigation bar
     */
    hasNavbar?: boolean;
    /**
     * Can navigate back in the platform's history
     *
     * Back icon location ('left' means left sidenav, 'right' means icon in the appBar, will show 'right' if no value provided)
     */
    canGoBack?: 'appBar' | 'navBar' | 'sideNav' | null;
    /**
     * Label
     */
    title?: string;
    /**
     * Label size
     */
    titleSize?: number;
    /**
     * Sub title
     */
    subtitle?: string;
    /**
     * Size of left sidenav
     */
    size?: DrawerSizeEnum;
    /**
     * Background image for left sidenav
     */
    bgImageUrl?: string;
    /**
     * Sidenav items
     */
    items?: DrawerNavItem[];
    /**
     * Navbar items
     */
    navbarItems?: DrawerNavItem[];
    /**
     * Merge config with parent
     */
    mergeWithParent?: boolean;
    /**
     * selectedExternalResource
     */
    selectedExternalResource$?: Observable<ExternalResource | null>;
    /**
     * should show validation error
     */
    validationError$?: Observable<boolean>;
}
