import { createAction, props } from '@ngrx/store';
import { Encounter } from '@mona/models';
import { AuditEvent } from '../../../../../shared/src/lib/types/r4b.type';

/**
 * load audit log
 */
export const loadAuditLogs = createAction('AUDIT_LOG:LOAD_AUDIT_LOGS', props<{ encounter: Encounter }>());
/**
 * load audit log Success
 */
export const loadAuditLogsSuccess = createAction(
    'AUDIT_LOG:LOAD_AUDIT_LOGS_SUCCEEDED',
    props<{ auditLogs: AuditEvent[] }>(),
);
/**
 * load audit log Failure
 */
export const loadAuditLogsFailure = createAction('AUDIT_LOG:LOAD_AUDIT_LOGS_FAILED', props<{ error: any }>());
