/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, HostBinding, isDevMode } from '@angular/core';
import { GIT_VERSION } from '@environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DrawerSizeEnum, LayoutConfig } from '@mona/ui';
import { AppService } from './app.service';

/**
 * AppComponent
 */
@Component({
    selector: 'mona-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    isDevMode = isDevMode();
    variant = 'persistent';
    sideBorder = true;
    state = { open: true, selected: undefined };

    /**
     * App Alert messages
     */
    alerts$ = this.appService.alerts$;
    /**
     * Last Alert
     *
     * @deprecated Workaround until alerts are moved to the top of the screen
     */
    lastAlert$ = this.alerts$.pipe(map(messages => messages?.pop()));
    /**
     * Layout & navigation config
     */
    layoutConfig$: Observable<LayoutConfig> = this.appService.layoutConfig$;
    /**
     * Git version
     */
    @HostBinding('attr.git-version') gitVersion = (GIT_VERSION as any)?.tag;

    /**
     * Constructor
     *
     * @param appService
     */
    constructor(private appService: AppService) {
        this.init();
    }

    /**
     * Init
     */
    init(): void {
        this.appService.checkPlatformFeatures();
        this.appService.initNavigationListener();
        this.appService.initRedirect();
        this.appService.initAutoLock();
        this.appService.handleUpdateAvailable();
        this.appService.handleLayoutEvents();
        this.appService.initRFIDListener();
        this.appService.checkTasksOverdueTime();
        this.appService.cleanUpWebBrowser();
    }

    /**
     * getDrawerSize
     *
     * @param size
     */
    getDrawerSize(size: DrawerSizeEnum) {
        return size === DrawerSizeEnum.LARGE ? 800 : size === DrawerSizeEnum.MEDIUM ? 500 : 350;
    }

    /** Navigate back */
    goBack() {
        this.appService.goBack();
    }
}
