import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Practitioner, PractitionerRole } from '@mona/models';
import { PractitionersActions, PractitionersSelectors } from '../state';

/**
 * Practitioners facade service
 */
@Injectable({
    providedIn: 'root',
})
export class PractitionersFacade {
    /** Is loading */
    isLoading$: Observable<boolean> = this.store.select(PractitionersSelectors.selectPractitionersStateIsLoading);
    /**
     * Get practitioners map
     */
    practitionersIds$: Observable<string[]> = this.store.select<string[]>(
        PractitionersSelectors.selectPractitionersIds,
    );
    /**
     * Get practitioners map
     */
    practitionersMap$: Observable<EntityMap<Practitioner>> = this.store.select(
        PractitionersSelectors.selectPractitionersEntities,
    );
    /**
     * Get loaded doctors
     */
    doctors$: Observable<Practitioner[]> = this.store.select(
        PractitionersSelectors.selectAllPractitionersByRole(PractitionerRole.Doctor),
    );
    /**
     * Get loaded nurses
     */
    nurses$: Observable<Practitioner[]> = this.store.select(
        PractitionersSelectors.selectAllPractitionersByRole(PractitionerRole.Nurse),
    );
    /**
     * Constructor
     *
     * @param store store
     */
    constructor(private store: Store<any>) {}

    /**
     * Load practitioners by ids
     *
     * @param ids EntityId<Practitioner>[]
     */
    loadPractitioners(ids?: EntityId<Practitioner>[]): void {
        this.store.dispatch(PractitionersActions.loadPractitioners({ ids }));
    }

    /**
     * Load nurses
     */
    loadNurses(): void {
        this.store.dispatch(PractitionersActions.loadPractitioners({ role: PractitionerRole.Nurse }));
    }

    /**
     * Get practitioner by id
     * @param id
     */
    getPractitionerById(id: string): Observable<Practitioner> {
        return this.store.select(PractitionersSelectors.selectPractitionerById(id));
    }

    /**
     * Clear load nurses action
     */
    clearLoadNurses(): void {
        // TODO: why do we need to clear?
        // this.store.dispatch(PractitionersActions.clearPractitioners());
    }
}
